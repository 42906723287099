import { useContext } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

import { SensitiveToggleContext } from './SensitiveToggleContext'

export default function SensitiveToggle() {
  const { showSensitive: showTotals, toggleShowSensitive } = useContext(
    SensitiveToggleContext,
  )

  return (
    <button
      type="button"
      onClick={() => toggleShowSensitive()}
      className="text-sm w-6"
    >
      {showTotals ? (
        <EyeIcon className="text-white-500" title="Hide Totals" />
      ) : (
        <EyeSlashIcon className="text-white-500" title="Show Totals" />
      )}
    </button>
  )
}
