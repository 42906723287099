import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getAccount } from './queries'
import { createAccount, deleteAccount, updateAccount } from './persistence'
import { Account, AccountType } from '../../types'
import MoneyAmount from '../../MoneyAmount'
import ErrorMessage from '../../ErrorMessage'
import { accountEditRoute, rootRoute } from '../../../routes'

export default function AccountForm() {
  const { accountId } = useParams<{ accountId: string }>()
  const isNewAccount = !accountId

  const navigate = useNavigate()

  const {
    isLoading,
    isError,
    error: loadingError,
    data: account = {} as Account,
  } = useQuery({
    queryKey: ['account', accountId],
    queryFn: () => getAccount(accountId || 'none'), // TODO: Review this none
    enabled: !isNewAccount,
  })

  const [hidden, setHidden] = useState(account.hidden || false)
  const [name, setName] = useState<string>(account.name || '')
  const [type, setType] = useState<AccountType>(account.type ?? 'ASSET')

  const queryClient = useQueryClient()
  const createMutation = useMutation({
    mutationFn: async (a: Account) =>
      createAccount({ ...a, hidden, name, type }),
    onSuccess: (createdAccount: Account) =>
      queryClient.invalidateQueries({ queryKey: ['accounts'] }).then(() => {
        navigate(accountEditRoute(createdAccount.id))
      }),
    onError: (error) => console.error('Error creating account:', error),
  })

  const updateMutation = useMutation({
    mutationFn: async (a: Account) =>
      updateAccount({ ...a, hidden, name, type }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['accounts'] }),
  })

  const deleteMutation = useMutation({
    mutationFn: deleteAccount,
    onSuccess: () =>
      queryClient
        .invalidateQueries({ queryKey: ['accounts'] })
        .then(() => navigate(rootRoute())),
  })

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isNewAccount) {
      createMutation.mutate(account)
    } else {
      updateMutation.mutate(account)
    }
  }

  const handleDelete = async () => {
    if (isNewAccount) {
      return
    }

    // eslint-disable-next-line no-alert
    if (!window.confirm('Are you sure you want to delete this account?')) {
      return
    }

    deleteMutation.mutate(account)
  }

  const handleHiddenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHidden(event.target.checked)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType(event.target.value as AccountType)
  }

  useEffect(() => {
    if (account && account.id) {
      setHidden(account.hidden)
      setName(account.name)
      setType(account.type)
    }
  }, [account])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <ErrorMessage error={loadingError} />
  }

  return (
    <div className="p-5">
      <h2 className="leading-5 text-lg my-3">
        {isNewAccount ? 'Creating Account' : 'Editing Account'}
      </h2>

      <form onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-2 gap-y-3 w-96">
          {!isNewAccount && (
            <>
              <label htmlFor="accountId">ID:</label>
              <div>{account.id}</div>

              <label htmlFor="balance">Balance:</label>
              <MoneyAmount amountInCents={account.balance} showCents color />
            </>
          )}

          <label htmlFor="name">Name:</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={handleNameChange}
          />

          <label htmlFor="hidden">Hidden:</label>
          <div>
            <input
              type="checkbox"
              name="hidden"
              id="hidden"
              checked={hidden}
              value=""
              onChange={handleHiddenChange}
            />
          </div>

          <label htmlFor="type">Type:</label>
          <select name="type" value={type} onChange={handleTypeChange}>
            <option value="INCOME">Income</option>
            <option value="EXPENSE">Expense</option>
            <option value="ASSET">Asset</option>
            <option value="LIABILITY">Liability</option>
          </select>

          <div />
          <div>
            <button
              type="submit"
              className="button button-primary"
              disabled={createMutation.isPending || updateMutation.isPending}
            >
              {isNewAccount ? 'Create' : 'Save'}
            </button>

            <button
              type="button"
              className="button button-default"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>

            {!isNewAccount && (
              <button
                type="button"
                className="button button-danger"
                onClick={handleDelete}
                disabled={
                  createMutation.isPending ||
                  updateMutation.isPending ||
                  deleteMutation.isPending
                }
              >
                Delete
              </button>
            )}

            {createMutation.error && (
              <ErrorMessage error={createMutation.error} />
            )}
            {updateMutation.error && (
              <ErrorMessage error={updateMutation.error} />
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
