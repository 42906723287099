import { formatDateToYMD } from './util'
import {
  OnSelectPeriodArgs,
  MS_IN_A_DAY,
} from './components/PeriodSelect/PeriodSelect'

export type PeriodId =
  | 'today'
  | 'this-month'
  | 'last-month'
  | 'last-30-days'
  | 'last-3-months'
  | 'this-year'
  | 'last-6-months'
  | 'last-12-months'
  | 'last-year'
  | 'all-time'
  | 'custom'

export const allPeriodIds: PeriodId[] = [
  'today',
  'this-month',
  'last-month',
  'last-30-days',
  'last-3-months',
  'this-year',
  'last-6-months',
  'last-12-months',
  'last-year',
  'all-time',
  'custom',
]

const periodNames: Record<PeriodId, string> = {
  today: 'Today',
  'this-month': 'This Month',
  'last-month': 'Last Month',
  'last-30-days': 'Last 30 Days',
  'last-3-months': 'Last 3 Months',
  'this-year': 'This Year',
  'last-6-months': 'Last 6 Months',
  'last-12-months': 'Last 12 Months',
  'last-year': 'Last Year',
  'all-time': 'All Time',
  custom: 'Custom',
}

export type Period = {
  id: PeriodId
  startDate: string
  endDate: string
}

export const DEFAULT_PERIOD_ID = 'last-12-months'

export const CUSTOM_PERIOD_ID: PeriodId = 'custom'

export const getPeriodName = (periodId: PeriodId): string =>
  periodNames[periodId]

export const getPeriod = (periodId: PeriodId): OnSelectPeriodArgs => {
  switch (periodId) {
    case 'today':
      return {
        startDate: formatDateToYMD(new Date()),
        endDate: formatDateToYMD(new Date()),
        periodId,
      }
    case 'this-month':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        ),
        endDate: formatDateToYMD(new Date()),
        periodId,
      }
    case 'last-month':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        ),
        endDate: formatDateToYMD(
          new Date(new Date().getFullYear(), new Date().getMonth(), 0),
        ),
        periodId,
      }
    case 'last-30-days':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getTime() - 30 * MS_IN_A_DAY),
        ),
        endDate: formatDateToYMD(new Date()),
        periodId,
      }
    case 'last-3-months':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getTime() - 90 * MS_IN_A_DAY),
        ),
        endDate: formatDateToYMD(new Date()),
        periodId,
      }
    case 'this-year':
      return {
        startDate: formatDateToYMD(new Date(new Date().getFullYear(), 0, 1)),
        endDate: formatDateToYMD(new Date()),
        periodId,
      }
    case 'last-6-months':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getTime() - 180 * MS_IN_A_DAY),
        ),
        endDate: formatDateToYMD(new Date()),
        periodId,
      }
    case 'last-12-months':
      return {
        startDate: formatDateToYMD(
          new Date(
            new Date().getFullYear() - 1,
            new Date().getMonth(),
            new Date().getDate(),
          ),
        ),
        endDate: formatDateToYMD(new Date()),
        periodId,
      }
    case 'last-year':
      return {
        startDate: formatDateToYMD(
          new Date(new Date().getFullYear() - 1, 0, 1),
        ),
        endDate: formatDateToYMD(
          new Date(new Date().getFullYear() - 1, 11, 31),
        ),
        periodId,
      }
    case 'all-time':
      return {
        startDate: formatDateToYMD(new Date(0)),
        endDate: formatDateToYMD(new Date('2999-12-31')),
        periodId,
      }
    default:
      return {
        startDate: formatDateToYMD(new Date(0)),
        endDate: formatDateToYMD(new Date('2999-12-31')),
        periodId,
      }
  }
}

export const allNonCustomPeriods = (): Period[] =>
  allPeriodIds
    .filter((periodId) => periodId !== CUSTOM_PERIOD_ID)
    .map((id) => {
      const { startDate, endDate } = getPeriod(id)
      return { id, startDate, endDate }
    })
