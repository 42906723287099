import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { moneyInMajors } from '../../util'

const DEBUG = false

export default function AmountEditor({
  amount,
  inputName,
  className,
  onChange,
  onKeyDown,
}: {
  amount: number
  inputName?: string | null
  className?: string | null
  onChange?: ((newAmountInCents: number) => void) | null
  onKeyDown?: ((evt: React.KeyboardEvent<HTMLInputElement>) => void) | null
}): JSX.Element {
  const [value, setValue] = useState<number>(moneyInMajors(amount))
  const [currentOperator, setCurrentOperator] = useState<string | null>(null)
  const [isWaitingForOperand, setIsWaitingForOperand] = useState<boolean>(false)

  const log = (...data: unknown[]): void => {
    if (!DEBUG) return

    // eslint-disable-next-line no-console
    console.log('AmountEditor', ...data)
  }

  const triggerOnChange = (newValueInMajors: number) => {
    const newValueInCents = Math.round(newValueInMajors * 100)
    log('triggering onChange with ', { newValueInCents })
    onChange?.(newValueInCents)
  }

  const calculate = () => {
    log('calculating', { amount, value, currentOperator })
    let total = moneyInMajors(amount)
    switch (currentOperator) {
      case '+':
        total += value
        break
      case '-':
        total -= value
        break
      case '*':
        total *= value
        break
      case '/':
        total /= value
        break
      default:
        break
    }
    log('calculated', { total })
    triggerOnChange(total)
    setValue(total)
    return total
  }

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    let triggerParentKeyDown = true
    const { key } = evt

    log('key down', {
      key,
      amount,
      value,
      currentOperator,
      isWaitingForOperand,
    })

    switch (key) {
      // Clear/zero
      case 'c':
        triggerOnChange(0)
        setValue(0)
        setCurrentOperator(null)
        break

      // Toogle sign
      case 't':
        triggerOnChange(-moneyInMajors(amount))
        setValue(-moneyInMajors(amount))
        setCurrentOperator(null)
        break

      // Operators
      case '+':
      case '-':
      case '*':
      case '/':
        calculate()
        setCurrentOperator(key)
        setIsWaitingForOperand(true)

        evt.preventDefault()
        break

      case 'Enter':
        calculate()
        setCurrentOperator(null)
        evt.preventDefault()
        evt.stopPropagation()
        triggerParentKeyDown = !currentOperator
        break

      // Calculate in-progress operation
      case '=':
        setCurrentOperator(null)
        calculate()
        break

      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9': {
        if (isWaitingForOperand) {
          const newValue = parseInt(key, 10)
          if (currentOperator === '-' && amount === 0) {
            log('was waiting for operand, setting negative value', -newValue)
            setValue(-newValue)
            setCurrentOperator(null)
          } else {
            setValue(newValue)
          }

          setIsWaitingForOperand(false)
          evt.preventDefault()
        }
        break
      }

      default:
        break
    }

    if (triggerParentKeyDown) {
      log('triggering parent key down')
      onKeyDown?.(evt)
    } else {
      log('not triggering parent key down')
    }
  }

  const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
    let inputValue = parseFloat(evt.currentTarget.value)
    if (Number.isNaN(inputValue)) {
      inputValue = 0
    }
    setValue(inputValue)
    if (!currentOperator) {
      triggerOnChange(inputValue)
    }
  }

  const handleBlur = () => {
    calculate()
    setCurrentOperator(null)
  }

  log('rendering', { amount, value })

  useEffect(() => {
    setValue(moneyInMajors(amount))
  }, [amount])

  return (
    <input
      type="number"
      name={inputName || undefined}
      value={value}
      step={0.01}
      placeholder="0.00"
      size={8}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      className={classNames('text-right', className)}
    />
  )
}
