import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getInOutDashboard } from './queries'
import MoneyAmount from '../MoneyAmount'
import ErrorMessage from '../ErrorMessage'
import {
  allNonCustomPeriods,
  getPeriod,
  getPeriodName,
  PeriodId,
} from '../../periods'

export default function InOutDashboard() {
  const { data, isLoading, error } = useQuery({
    queryFn: () => getInOutDashboard({ periods: allNonCustomPeriods() }),
    queryKey: ['inOutDashboard'],
  })

  const periodText = (periodId: PeriodId) => {
    const { startDate, endDate, periodId: periodName } = getPeriod(periodId)
    return (
      <span title={`${startDate} to ${endDate}`}>
        {getPeriodName(periodName as PeriodId)}
      </span>
    )
  }

  const renderData = () => {
    if (isLoading) {
      return 'Loading...'
    }

    if (error) {
      return <ErrorMessage error={error} />
    }

    return data?.map((inOutPeriod) => (
      <React.Fragment key={inOutPeriod.periodId}>
        <div>{periodText(inOutPeriod.periodId as PeriodId)}</div>
        <div className="text-right">
          <MoneyAmount
            amountInCents={inOutPeriod.in}
            color
            showCents={false}
            sensitive
          />
        </div>
        <div className="text-right">
          <MoneyAmount
            amountInCents={-inOutPeriod.out}
            color
            showCents={false}
            sensitive
          />
        </div>
        <div className="text-right">
          <MoneyAmount
            amountInCents={inOutPeriod.remainder}
            color
            showCents={false}
            sensitive
          />
        </div>
      </React.Fragment>
    ))
  }

  return (
    <div>
      <h1 className="text-center text-lg mb-3">In/Out</h1>
      <div className="grid grid-cols-4 gap-y-1">
        <div>Period</div>
        <div className="text-right text-lg font-bold">+</div>
        <div className="text-right text-lg font-bold">-</div>
        <div className="text-right text-lg font-bold">=</div>
        {renderData()}
      </div>
    </div>
  )
}
