import { TransactionSplit } from '../types'

export const formatSplitText = (
  splits: TransactionSplit[],
  field: keyof TransactionSplit,
): string => {
  if (!splits.find((split) => split[field])) {
    return ''
  }

  return `Split into ${splits.length} parts`
}
