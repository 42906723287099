export type AccountsCategory = 'all' | 'cash_flow' | 'net_worth'

export type AccountHiddenFilter = 'all' | 'onlyHidden' | 'onlyVisible'

export type AccountType = 'ASSET' | 'LIABILITY' | 'INCOME' | 'EXPENSE'

export type Account = {
  id: string
  name: string
  balance: number
  hidden: boolean
  type: AccountType
}

export type TransactionSplit = {
  description: string
  amount: number
  tag: string
  matchId: string | null
  matchAccountId: string
  matchAccountName?: string | null
}

export type Transaction = {
  id: string
  accountId: string
  accountName?: string | null
  date: string
  payee: string
  description: string
  amount: number
  splits: TransactionSplit[]
  reconciled: boolean
}

export type Category = {
  id: string
  name: string
}

export type Tag = {
  id: string
  name: string
}

export type Payee = {
  name: string
}

export type BillFrequency = 'MONTHLY' | 'YEARLY'

export type Bill = {
  id: string
  payee: string
  accountId: string
  accountName: string
  splits: TransactionSplit[]
  description: string
  amount: number
  day: number
  month: number | null
  frequency: BillFrequency
  times: number
  autoInsert: boolean
}

export type AccountSummary = {
  totalBalance: number
  netWorthBalance: number
}

export const TRANSACTION_NEW_ID = 'new'
