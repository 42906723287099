const LOCAL_STORAGE_KEY = 'showTotals'

export const storeSensitiveToggle = (showTotals: boolean): void =>
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(showTotals))

export const getStoredSensitiveToggle = (): boolean => {
  // eslint-disable-next-line no-console
  console.log('getting sensitive')
  const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (storedValue) {
    return JSON.parse(storedValue)
  }
  return true
}
