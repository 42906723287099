import React, { useMemo } from 'react'
import { ListBulletIcon } from '@heroicons/react/24/solid'
import { formatDateToDisplay } from '../../util'
import MoneyAmount from '../MoneyAmount'
import { Transaction } from '../types'
import TransactionPresenter from './TransactionPresenter'
import { formatSplitText } from './transaction'
import withEllipsis from '../common'

export default function TransactionEntryShow({
  transaction,
  balance,
  selected,
  rowRef,
  onStartEdit,
  onSelect,
}: {
  transaction: Transaction
  balance: number
  selected: boolean
  rowRef: React.RefObject<HTMLDivElement>
  onStartEdit: () => void
  onSelect: () => void
}) {
  const { id, date, payee, description, amount, splits, reconciled } =
    transaction

  const handleDblClick = () => {
    onStartEdit()
  }

  const handleClick = () => {
    onSelect()
  }

  const accountName = useMemo(() => {
    if (splits.length <= 1) {
      return splits[0].matchAccountName
    }

    return (
      <>
        <ListBulletIcon className="h-5 w-5 mr-1 inline" />
        {formatSplitText(splits, 'matchAccountId')}
      </>
    )
  }, [splits])

  const tagName = useMemo(() => {
    if (splits.length <= 1) {
      return splits[0].tag
    }

    return (
      <>
        <ListBulletIcon className="h-5 w-5 mr-1 inline" />
        {formatSplitText(splits, 'tag')}
      </>
    )
  }, [splits])

  return (
    <TransactionPresenter
      key={id}
      date={formatDateToDisplay(date)}
      payee={withEllipsis(payee)}
      category={withEllipsis(accountName)}
      tag={tagName}
      description={withEllipsis(description)}
      reconciled={
        reconciled && (
          <span title="Reconciled" className="cursor-default">
            R
          </span>
        )
      }
      amount={<MoneyAmount amountInCents={amount} color />}
      balanceOrActions={<MoneyAmount amountInCents={balance} />}
      onDblClick={handleDblClick}
      onClick={handleClick}
      selected={selected}
      rowRef={rowRef}
    />
  )
}
