import { authenticatedFetch } from '../../../auth-utils'
import { Account } from '../../types'

export const updateAccount = (account: Partial<Account>): Promise<void> =>
  authenticatedFetch(`/accounts/${account.id}`, {
    method: 'PUT',
    body: JSON.stringify(account),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
  })

export const createAccount = (account: Partial<Account>): Promise<Account> =>
  authenticatedFetch(`/accounts`, {
    method: 'POST',
    body: JSON.stringify(account),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return response.json()
  })

export const deleteAccount = (account: Account): Promise<void> =>
  authenticatedFetch(`/accounts/${account.id}`, {
    method: 'DELETE',
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
  })
