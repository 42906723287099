import { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { moneyInMajors } from '../util'
import { SensitiveToggleContext } from './TotalsToggle/SensitiveToggleContext'

export default function MoneyAmount({
  className,
  amountInCents,
  showCents = true,
  color,
  inverse,
  sensitive,
}: {
  className?: string
  amountInCents: number
  showCents?: boolean
  color?: boolean
  inverse?: boolean
  sensitive?: boolean
}) {
  const { showSensitive: showTotals } = useContext(SensitiveToggleContext)

  const amount = moneyInMajors(amountInCents) * (inverse ? -1 : 1)
  const digits = showCents ? 2 : 0

  const formattedAmount = useMemo(
    () =>
      sensitive && !showTotals
        ? '****'
        : amount.toLocaleString('en-US', {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
          }),
    [amount, digits, sensitive, showTotals],
  )

  const classes = classNames(className, {
    'text-red-700': color && amount < 0,
    'text-blue-700': color && amount > 0,
  })

  return <span className={classes}>{formattedAmount}</span>
}

MoneyAmount.propTypes = {
  amountInCents: PropTypes.number.isRequired,
  showCents: PropTypes.bool,
  inverse: PropTypes.bool,
}
