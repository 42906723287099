export const transactionsRoute = (
  accountId: string,
  params?: { focusId?: string },
): string =>
  `/accounts/${accountId}/transactions?${new URLSearchParams(params)}`

export const accountEditRoute = (accountId: string): string =>
  `/accounts/${accountId}/edit`

export const accountNewRoute = (): string => `/accounts/new`

export const rootRoute = (): string => `/`
