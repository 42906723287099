/* eslint-disable func-style */
import React, { useMemo, useState } from 'react'
import './App.css'
import './main.css'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import MainPage from './components/MainPage'
import LoginPage from './components/Login/LoginPage'
import { isLoggedIn } from './auth-utils'
import AccountForm from './components/Account/AccountForm/AccountForm'
import TransactionList from './components/TransactionList/TransactionList'
import MainDashboard from './components/MainDashboard/MainDashboard'
import { SensitiveToggleContext } from './components/TotalsToggle/SensitiveToggleContext'
import {
  getStoredSensitiveToggle,
  storeSensitiveToggle,
} from './components/TotalsToggle/persistence'
import BillsPage from './components/Bills/BillsPage'
import BillForm from './components/Bills/BillForm'

const queryClient = new QueryClient()

function PrivateRoutes(): JSX.Element {
  return isLoggedIn() ? <Outlet /> : <Navigate to="/signin" />
}

function App() {
  const [showSensitive, setShowSensitive] = useState(getStoredSensitiveToggle())

  const toggleState = useMemo(
    () => ({
      showSensitive,
      toggleShowSensitive: () => {
        setShowSensitive((prev) => !prev)
        storeSensitiveToggle(!showSensitive)
      },
    }),
    [showSensitive],
  )

  return (
    <div className="text-sm">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <SensitiveToggleContext.Provider value={toggleState}>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/"
                  element={
                    <MainPage>
                      <MainDashboard />
                    </MainPage>
                  }
                />
                <Route path="/accounts" element={<MainPage />} />
                <Route
                  path="/accounts/:accountId/transactions"
                  element={
                    <MainPage>
                      <TransactionList />
                    </MainPage>
                  }
                />
                <Route
                  path="/bills"
                  element={
                    <MainPage>
                      <BillsPage />
                    </MainPage>
                  }
                />
                <Route
                  path="/bills/new"
                  element={
                    <MainPage>
                      <BillForm />
                    </MainPage>
                  }
                />
                <Route
                  path="/bills/:billId/edit"
                  element={
                    <MainPage>
                      <BillForm />
                    </MainPage>
                  }
                />
                <Route
                  path="/accounts/new"
                  element={
                    <MainPage>
                      <AccountForm />
                    </MainPage>
                  }
                />
                <Route
                  path="/accounts/:accountId/edit"
                  element={
                    <MainPage>
                      <AccountForm />
                    </MainPage>
                  }
                />
              </Route>
              <Route path="/signin" element={<LoginPage />} />
              <Route path="/" element={<Navigate to="/accounts" />} />
            </Routes>
          </SensitiveToggleContext.Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
