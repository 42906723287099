import React from 'react'
import PropTypes from 'prop-types'
import { getTags } from './queries'
import Selector from './Selector'
import { createTag } from './persistence'

export default function TagSelector({
  tag,
  onChange,
  onKeyDown,
  className,
}: {
  tag: string
  onChange: (c: string) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  className?: string
}) {
  const getTagNames = async () => {
    const tags = await getTags()
    return tags.map(({ name }) => ({ label: name, value: name }))
  }

  return (
    <Selector
      value={tag}
      queryKey="tags"
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={className}
      queryFn={getTagNames}
      onNewValue={createTag}
    />
  )
}

TagSelector.propTypes = {
  tag: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
}
