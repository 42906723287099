import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Transaction } from '../types'
import TransactionEntryEdit, {
  TransactionEntryEditOutcome,
} from './TransactionEntryEdit'
import TransactionEntryShow from './TransactionEntryShow'

export default function TransactionEntry({
  transaction,
  balance,
  editing,
  onStartEdit,
  onStopEdit,
  onSelect,
  selected,
}: {
  transaction: Transaction
  balance: number
  onStartEdit: (t: Transaction) => void
  selected: boolean
  onSelect: () => void
  onStopEdit: (t: Transaction, outcome: TransactionEntryEditOutcome) => void
  editing: boolean
}) {
  const handleStartEdit = useCallback(() => {
    onStartEdit(transaction)
  }, [transaction])

  const rowRef = useRef<HTMLDivElement>(null)

  const handleEditComplete = useCallback(
    (outcome: TransactionEntryEditOutcome) => {
      onStopEdit(transaction, outcome)
    },
    [transaction],
  )

  const focusSelected = () => {
    rowRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
  }

  useEffect(() => {
    if (selected) {
      focusSelected()
    }
  }, [selected])

  if (editing) {
    return (
      <TransactionEntryEdit
        transaction={transaction}
        onComplete={handleEditComplete}
        rowRef={rowRef}
      />
    )
  }

  return (
    <TransactionEntryShow
      transaction={transaction}
      balance={balance}
      onStartEdit={handleStartEdit}
      onSelect={onSelect}
      selected={selected}
      rowRef={rowRef}
    />
  )
}

TransactionEntry.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    payee: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }).isRequired,
  balance: PropTypes.number.isRequired,
  editing: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onStartEdit: PropTypes.func.isRequired,
  onStopEdit: PropTypes.func.isRequired,
}
