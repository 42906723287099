import { authenticatedFetch } from '../../auth-utils'
import { Transaction } from '../types'

export type ReconcileState = {
  active: boolean
  confirming: boolean
  starting: number
  initial: number
  expected: number
  statuses: Record<string, boolean>
}

export type ReconcileParams = {
  transactions: Transaction[]
  state: ReconcileState
}

const reconcileSum = ({ transactions, state }: ReconcileParams): number =>
  transactions.reduce(
    (sum, t) => sum + (state.statuses[t.id] ? t.amount : 0),
    0,
  )

export const reconciliationDifference = ({
  transactions,
  state,
}: ReconcileParams): number =>
  state.starting + reconcileSum({ transactions, state }) - state.expected

export const isReconciliationReady = ({
  transactions,
  state,
}: ReconcileParams): boolean =>
  reconciliationDifference({ transactions, state }) === 0

export const reconciliationActualBalance = ({
  transactions,
  state,
}: ReconcileParams) => state.starting + reconcileSum({ transactions, state })

export const confirmReconciliation = async ({
  transactions,
  state,
}: ReconcileParams): Promise<void> => {
  const transactionIds = transactions
    .filter((t) => state.statuses[t.id])
    .map((t) => t.id)

  const response = await authenticatedFetch('/transactions/reconcile', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ transactionIds }),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
}
