import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SingleValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

export type SelectorItem = {
  value: string
  label: string
}
export default function Selector({
  value,
  queryFn,
  queryKey,
  onChange,
  onKeyDown,
  onNewValue,
  className,
}: {
  value: string
  queryFn: () => Promise<SelectorItem[]>
  queryKey: string
  onChange: (c: string) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  onNewValue: (c: string) => Promise<void>
  className?: string
}) {
  const classes = classNames(className)

  const [menuOpen, setMenuOpen] = useState(false)
  const queryClient = useQueryClient()
  const {
    isLoading,
    isError,
    data: options,
  } = useQuery({
    queryKey: [queryKey],
    queryFn,
  })

  const customStyles = {
    control: (provided: object) => ({
      ...provided,
      minHeight: '25px',
      height: '25px',
    }),
    valueContainer: (provided: object) => ({
      ...provided,
      height: '25px',
      padding: '0 6px',
    }),
    input: (provided: object) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: object) => ({
      ...provided,
      height: '25px',
    }),
    option: (provided: object) => ({
      ...provided,
      height: '25px',
      paddingTop: '2px',
      paddingBottom: '2px',
    }),
    menu: (provided: object) => ({
      ...provided,
      width: '400px',
      // top: undefined,
      // bottom: '100%',
    }),
  }

  const selectedLabel = useMemo(() => {
    const selectedOption = options?.find((option) => option.value === value)
    return selectedOption?.label
  }, [options, value])

  const handleChange = (
    newValue: SingleValue<{ value: string; label: string }>,
  ) => {
    if (newValue && newValue.value) {
      onChange(newValue.value)
    }
  }

  const handleCreateOption = async (inputValue: string) => {
    await onNewValue(inputValue)
    await queryClient.invalidateQueries({ queryKey: [queryKey] })
    onChange(inputValue)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Prevent ESC with menu open to cancel the edit of the whole register
    if (event.key === 'Escape' && !menuOpen) {
      onKeyDown?.(event)
    }
  }

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  if (isLoading) {
    return <div>Loading items...</div>
  }

  if (isError) {
    return <div>Error loading items</div>
  }

  return (
    <CreatableSelect
      className={classes}
      classNames={{ option: () => 'select-option' }}
      onChange={handleChange}
      options={options}
      value={{ value, label: selectedLabel ?? '-' }}
      styles={customStyles}
      onCreateOption={handleCreateOption}
      onKeyDown={handleKeyDown}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  )
}

Selector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
}
