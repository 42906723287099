import { authenticatedFetch } from '../../auth-utils'
import { Bill } from '../types'

export const getBill = async (billId: string): Promise<Bill> =>
  authenticatedFetch(`/bills/${billId}`).then((response) => response.json())

export const getBills = async (): Promise<Bill[]> =>
  authenticatedFetch('/bills').then((response) => response.json())

export const createBill = async (bill: Omit<Bill, 'id'>): Promise<Bill> => {
  const response = await authenticatedFetch('/bills', {
    method: 'POST',
    body: JSON.stringify(bill),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.json()
}

export const updateBill = async (bill: Bill): Promise<Bill> => {
  const response = await authenticatedFetch(`/bills/${bill.id}`, {
    method: 'PUT',
    body: JSON.stringify(bill),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.json()
}

export const deleteBill = async (billId: string): Promise<void> => {
  await authenticatedFetch(`/bills/${billId}`, {
    method: 'DELETE',
  })
}
