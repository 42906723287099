import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createBill, deleteBill, getBill, updateBill } from './persistence'
import AccountSelector from '../Selector/AccountSelector'
import { Bill, BillFrequency, TransactionSplit } from '../types'
import AmountEditor from '../TransactionEntry/AmountEditor'
import ErrorMessage from '../ErrorMessage'
import { getAccount } from '../Account/AccountForm/queries'
import MoneyAmount from '../MoneyAmount'

const DEFAULT_NEW_SPLIT: TransactionSplit = {
  amount: 0,
  description: '',
  matchAccountId: '',
  tag: '',
  matchId: '',
}

export default function BillForm() {
  const { billId } = useParams<{ billId: string }>()
  const isNewBill = !billId

  const formRef = useRef<HTMLFormElement>(null)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [accountName, setAccountName] = useState<string>('')
  const [frequency, setFrequency] = useState<BillFrequency>('MONTHLY')
  const [splits, setSplits] = useState<TransactionSplit[]>([DEFAULT_NEW_SPLIT])

  const hasSplits = splits.length > 1
  const totalAmount = useMemo(
    () => splits.reduce((sum, split) => sum + split.amount, 0),
    [splits],
  )

  const { data: bill, isLoading } = useQuery({
    queryKey: ['bill', billId],
    queryFn: () => getBill(billId || 'none'),
    enabled: !isNewBill,
  })

  const { data: { name: initialAccountName } = { name: '' } } = useQuery({
    queryKey: ['account', bill?.accountId],
    queryFn: () =>
      bill ? getAccount(bill.accountId) : Promise.resolve({ name: '' }),
    enabled: !!bill,
  })

  const createMutation = useMutation({
    mutationFn: createBill,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] })
      navigate('/bills')
    },
  })

  const updateMutation = useMutation({
    mutationFn: updateBill,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] })
      navigate('/bills')
    },
  })

  const deleteMutation = useMutation({
    mutationFn: deleteBill,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] })
      navigate('/bills')
    },
  })

  const showMonthField = frequency === 'YEARLY'

  const handleFrequencyChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setFrequency(event.target.value as BillFrequency)
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const form = event.currentTarget
    if (!form.checkValidity()) {
      return
    }

    const formValues = new FormData(form)

    const day = parseInt(formValues.get('day')?.toString() || '', 10)
    const month = formValues.get('month')
      ? parseInt(formValues.get('month')?.toString() ?? '', 10)
      : null

    const billParams = {
      description: formValues.get('description'),
      payee: formValues.get('payee'),
      frequency,
      day,
      month,
      amount: totalAmount,
      times: Number(formValues.get('times')),
      splits: [],
      accountName,
      autoInsert: formValues.get('autoInsert') === 'true',
    }

    if (isNewBill) {
      createMutation.mutate(billParams as unknown as Bill)
    } else {
      updateMutation.mutate({ ...billParams, id: billId } as unknown as Bill)
    }
  }

  const handleDelete = () => {
    if (
      !billId ||
      // eslint-disable-next-line no-alert
      !window.confirm('Are you sure you want to delete this bill?')
    ) {
      return
    }

    deleteMutation.mutate(billId)
  }

  useEffect(() => {
    setFrequency(bill?.frequency || 'MONTHLY')
    setSplits(bill?.splits || [DEFAULT_NEW_SPLIT])
  }, [bill])

  useEffect(() => {
    setAccountName(initialAccountName)
  }, [initialAccountName])

  return (
    <div className="p-1 lg:max-w-2xl">
      <h2 className="leading-5 text-lg my-3">
        {isNewBill ? 'Creating Bill' : 'Editing Bill'}
      </h2>

      <form onSubmit={handleFormSubmit} className="form" ref={formRef}>
        <div className="grid grid-cols-2 gap-y-3">
          <label htmlFor="accountName">Account:</label>
          <AccountSelector
            accountTypes="net_worth"
            accountId={accountName}
            onChange={setAccountName}
          />

          <label htmlFor="payee">Payee:</label>
          <input type="text" name="payee" required defaultValue={bill?.payee} />

          <label htmlFor="description">Description:</label>
          <input
            type="text"
            name="description"
            required
            defaultValue={bill?.description}
          />
        </div>

        {splits.map((split, index) => (
          <>
            <div className="border-t border-t-gray-300 mt-2">
              Split {index + 1}
            </div>

            {/* eslint-disable-next-line react/no-array-index-key */}
            <div className="grid grid-cols-2 gap-y-3 pl-5" key={index}>
              <label htmlFor={`category${index}`}>Category:</label>
              <AccountSelector
                accountTypes="all"
                accountId={split.matchAccountId}
                onChange={(matchAccountId) =>
                  setSplits([
                    ...splits.slice(0, index),
                    { ...split, matchAccountId },
                    ...splits.slice(index + 1),
                  ])
                }
              />
              <label htmlFor={`tag${index}`}>Tag:</label>
              <input
                type="text"
                name={`tag${index}`}
                defaultValue={split.tag}
                onChange={(event) =>
                  setSplits([
                    ...splits.slice(0, index),
                    { ...split, tag: event.target.value },
                    ...splits.slice(index + 1),
                  ])
                }
              />
              <label htmlFor={`amount${index}`}>Amount:</label>
              <AmountEditor
                inputName={`amount${index}`}
                amount={split.amount}
                onChange={(newAmount) =>
                  setSplits([
                    ...splits.slice(0, index),
                    { ...split, amount: newAmount },
                    ...splits.slice(index + 1),
                  ])
                }
              />

              <div />
              {hasSplits && (
                <div>
                  <button
                    className="button button-danger"
                    type="button"
                    onClick={() =>
                      setSplits([
                        ...splits.slice(0, index),
                        ...splits.slice(index + 1),
                      ])
                    }
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          </>
        ))}

        <div />
        <div className="border-b border-b-gray-300 mb-2">
          <button
            className="button button-default"
            type="button"
            onClick={() => setSplits([...splits, DEFAULT_NEW_SPLIT])}
          >
            Add Split
          </button>
        </div>

        <div className="grid grid-cols-2 gap-y-3">
          <label htmlFor="amount">Total Amount:</label>
          <MoneyAmount amountInCents={totalAmount} color />

          <label htmlFor="frequency">Frequency:</label>
          <select
            name="frequency"
            required
            value={frequency}
            onChange={handleFrequencyChange}
          >
            <option value="MONTHLY">Monthly</option>
            <option value="YEARLY">Yearly</option>
          </select>

          <label htmlFor="day">Day:</label>
          <input
            type="number"
            name="day"
            required
            min="1"
            max="31"
            defaultValue={bill?.day}
          />

          {showMonthField && (
            <>
              <label htmlFor="month">Month:</label>
              <input
                type="number"
                name="month"
                defaultValue={bill?.month || undefined}
              />
            </>
          )}

          <label htmlFor="times">Times:</label>
          <input
            type="number"
            name="times"
            defaultValue={bill?.times || undefined}
          />

          <label htmlFor="autoInsert">Auto Insert:</label>
          <input
            type="checkbox"
            name="autoInsert"
            value="true"
            defaultChecked={bill?.autoInsert}
          />

          <div />

          <div>
            <button
              className="button button-primary"
              type="submit"
              disabled={
                isLoading ||
                createMutation.isPending ||
                updateMutation.isPending
              }
            >
              {isNewBill ? 'Create' : 'Save'}
            </button>

            <button
              className="button button-default"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            {!isNewBill && (
              <button
                className="button button-danger"
                type="button"
                onClick={handleDelete}
                disabled={isLoading || deleteMutation.isPending}
              >
                Delete
              </button>
            )}

            {createMutation.error && (
              <ErrorMessage error={createMutation.error} />
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
