import React, { ReactNode } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Link, useParams, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import {
  AccountsCategory,
  AccountHiddenFilter,
  type Account,
} from '../../types'
import { getAccountSummary as getAccountsSummary, getAccounts } from './queries'
import MoneyAmount from '../../MoneyAmount'
import { accountNewRoute, transactionsRoute } from '../../../routes'

export default function AccountList() {
  const navigate = useNavigate()
  const { accountId } = useParams<{ accountId: string }>() as {
    accountId: string
  }

  const [accountCategory, setAccountCategory] =
    React.useState<AccountsCategory>('net_worth')
  const [hiddenFilter, setHiddenAccounts] =
    React.useState<AccountHiddenFilter>('onlyVisible')

  const {
    isLoading,
    isError,
    data: accounts,
  } = useQuery({
    queryKey: ['accounts', { accountCategory, hiddenFilter }],
    queryFn: () => getAccounts({ accountCategory, hiddenFilter }),
  })

  const { data: summary } = useQuery({
    queryKey: ['accounts', 'summary'],
    queryFn: () => getAccountsSummary(),
  })

  const handleAccountCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setAccountCategory(event.target.value as AccountsCategory)
  }

  const handleDoubleClick = (id: string) => {
    // if (accountId === id) {
    // return
    // }
    navigate(`/accounts/${id}/edit`)
  }

  const handleAccountHiddenChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => setHiddenAccounts(event.target.value as AccountHiddenFilter)

  const renderTypeIndicator = (account: Account): ReactNode => {
    switch (account.type) {
      case 'ASSET':
        return ''
      case 'LIABILITY':
        return ''
      case 'INCOME':
        return '(+)'
      case 'EXPENSE':
        return '(-)'
      default:
        return ''
    }
  }

  const renderAccount = (account: Account) => (
    <Link
      to={transactionsRoute(account.id)}
      className="p-1 border-b border-gray-500 flex"
      key={account.id}
      onDoubleClick={() => handleDoubleClick(account.id)}
    >
      <div className="flex-grow-0 flex-shrink-0 w-2">
        {accountId === account.id ? '•' : ''}
      </div>
      <div
        className={classNames('flex-shrink-0 flex-grow w-32', {
          'text-gray-500': account.hidden,
        })}
      >
        <div
          className="whitespace-nowrap text-ellipsis overflow-hidden"
          title={account.name}
        >
          {renderTypeIndicator(account)}
          {account.name}
        </div>
      </div>
      <div className="flex-shrink flex-grow-0 w-20 text-right">
        <MoneyAmount
          amountInCents={account.balance}
          showCents={false}
          color
          sensitive
        />
      </div>
    </Link>
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <div className="account-list bg-blue-100">
      <h1 className="p-3 border-b-2 border-gray-500 font-medium">
        Accounts (
        {summary ? (
          <MoneyAmount
            amountInCents={summary?.netWorthBalance}
            showCents={false}
            color
            sensitive
          />
        ) : (
          '...'
        )}
        )
      </h1>
      <div>
        <select onChange={handleAccountHiddenChange} value={hiddenFilter}>
          <option value="all">All</option>
          <option value="onlyHidden">Only Hidden</option>
          <option value="onlyVisible">Only Visible</option>
        </select>
        <select onChange={handleAccountCategoryChange} value={accountCategory}>
          <option value="all">All</option>
          <option value="cash_flow">Cash Flow</option>
          <option value="net_worth">Net Worth</option>
        </select>
      </div>

      <div className="bg-blue-100">{accounts?.map(renderAccount)}</div>

      <div className="bg-blue-100">
        <Link
          to={accountNewRoute()}
          className="p-1 border-gray-500 flex italic"
        >
          Create New Account
        </Link>
      </div>
    </div>
  )
}
