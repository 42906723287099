import React, { useState } from 'react'
import DateEditor from '../DateEditor/DateEditor'
import {
  allPeriodIds,
  CUSTOM_PERIOD_ID,
  DEFAULT_PERIOD_ID,
  getPeriod,
  getPeriodName,
  PeriodId,
} from '../../periods'

export type OnSelectPeriodArgs = {
  startDate: string
  endDate: string
  periodId: string
}

type Props = {
  onSelect: ({ startDate, endDate, periodId }: OnSelectPeriodArgs) => void
}

export const MS_IN_A_DAY = 24 * 60 * 60 * 1000

// This can be customized/saved in the browser
export const defaultSelectPeriod = (): OnSelectPeriodArgs =>
  getPeriod(DEFAULT_PERIOD_ID)

export default function PeriodSelect({ onSelect }: Props) {
  const [startDate, setStartDate] = useState<string>(
    defaultSelectPeriod().startDate,
  )
  const [endDate, setEndDate] = useState<string>(defaultSelectPeriod().endDate)
  const [periodName, setPeriodName] = useState(DEFAULT_PERIOD_ID)

  const customDateRange = periodName === CUSTOM_PERIOD_ID

  const selectPeriod = ({
    periodName: newPeriodName,
    startDate: newStartDate,
    endDate: newEndDate,
  }: {
    periodName: string
    startDate?: string
    endDate?: string
  }) => {
    if (newStartDate) {
      setStartDate(newStartDate)
    }
    if (newEndDate) {
      setEndDate(newEndDate)
    }
    setPeriodName(newPeriodName)

    onSelect({
      startDate: newStartDate || startDate,
      endDate: newEndDate || endDate,
      periodId: newPeriodName,
    })
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPeriodName = event.target.value as PeriodId

    if (newPeriodName === CUSTOM_PERIOD_ID) {
      selectPeriod({ periodName: newPeriodName })
    } else {
      const { startDate: newStartDate, endDate: newEndDate } =
        getPeriod(newPeriodName)

      selectPeriod({
        periodName: newPeriodName,
        startDate: newStartDate,
        endDate: newEndDate,
      })
    }
  }

  return (
    <div className="space-x-2">
      <label htmlFor="period-selector">
        Period:
        <select
          id="period-selector"
          onChange={handleSelectChange}
          className="rounded-md"
          value={periodName}
        >
          {allPeriodIds.map((periodId) => (
            <option key={periodId} value={periodId}>
              {getPeriodName(periodId)}
            </option>
          ))}
        </select>
      </label>

      {customDateRange && (
        <>
          <DateEditor
            className="rounded-md"
            date={startDate}
            disabled={!customDateRange}
            onDateChange={(newDate) =>
              selectPeriod({
                periodName: CUSTOM_PERIOD_ID,
                startDate: newDate,
              })
            }
          />
          <DateEditor
            className="rounded-md"
            date={endDate}
            disabled={!customDateRange}
            onDateChange={(newDate) =>
              selectPeriod({ periodName: CUSTOM_PERIOD_ID, endDate: newDate })
            }
          />
        </>
      )}
    </div>
  )
}
