import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { getCashFlowDashboard } from './queries'
import MoneyAmount from '../MoneyAmount'
import { CashFlowDashboardDataEntry } from './types'
import PeriodSelect, {
  defaultSelectPeriod,
  OnSelectPeriodArgs,
} from '../PeriodSelect/PeriodSelect'
import { formatPercent } from '../../util'
import { transactionsRoute } from '../../routes'
import ErrorMessage from '../ErrorMessage'

export default function CashFlowDashboard() {
  const [startDate, setStartDate] = React.useState(
    defaultSelectPeriod().startDate,
  )
  const [endDate, setEndDate] = React.useState(defaultSelectPeriod().endDate)

  const { data, isLoading, error } = useQuery({
    queryFn: () => getCashFlowDashboard({ startDate, endDate, limit: 10 }),
    queryKey: ['cashFlowDashboard', [startDate, endDate]],
  })

  const handlePeriodSelect = ({
    startDate: newStartDate,
    endDate: newEndDate,
  }: OnSelectPeriodArgs) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const renderCashFlowAccount = (
    cashFlowAccount: CashFlowDashboardDataEntry,
  ) => (
    <div
      className="flex justify-evenly border-b-orange-300 border-b-2"
      key={cashFlowAccount.accountId}
    >
      <div
        className="basis-1/2 text-ellipsis overflow-hidden whitespace-nowrap"
        title={cashFlowAccount.accountName}
      >
        {cashFlowAccount.accountId ? (
          <Link to={transactionsRoute(cashFlowAccount.accountId)}>
            {cashFlowAccount.accountName}
          </Link>
        ) : (
          cashFlowAccount.accountName
        )}
      </div>
      <div className="basis-1/4 text-right">
        {formatPercent(cashFlowAccount.percent, 0)}
      </div>
      <div className="basis-1/4 text-right">
        <MoneyAmount
          amountInCents={cashFlowAccount.amountInCents}
          showCents={false}
        />
      </div>
    </div>
  )

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <div>
      <h2 className="text-lg mb-3">Expenses by Category</h2>
      <PeriodSelect onSelect={handlePeriodSelect} />

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="mt-3">
          {data?.entries.map(renderCashFlowAccount)}
          {renderCashFlowAccount({
            accountName: 'Total',
            amountInCents: data?.sum || 0,
            percent: 1,
          })}
        </div>
      )}
    </div>
  )
}
